// TODO: REFACTORING
import React, { useState } from 'react';
import * as Styled from './ProposalCardFooter.style';

import Button from '../Button';

import { translate as t } from '@utils/translate';

const ProposalCardFooterDesktop = ({
  handleMoreDetails,
  showingMoreDetails,
  agreementAccepted,
  handleAcceptProposal,
  isAcceptable,
}) => {
  const TEXTS = {
    WARNING: t('acceptWarning'),
    BUTTON_CLOSE_LABEL: t('close'),
    BUTTON_DETAILS_LABEL: t('proposalDetail'),
    BUTTON_ACCEPT_LABEL: t('acceptProposal'),
  };

  return (
    <div className={'proposal-card-footer'}>
      {showingMoreDetails ? (
        <>
          <div>
            <Button
              theme={'default'}
              onClick={() => handleMoreDetails(false)}
              label={TEXTS.BUTTON_CLOSE_LABEL}
            />
          </div>
        </>
      ) : (
        <Button
          theme={'active'}
          onClick={() => handleMoreDetails(true)}
          label={TEXTS.BUTTON_DETAILS_LABEL}
        />
      )}
    </div>
  );
};

const ProposalCardFooterMobile = ({
  handleMoreDetails,
  showingMoreDetails,
  handleSeeAgreement,
}) => {
  const TEXTS = {
    BUTTON_AGREEMENT_LABEL: t('chooseThisOption'),
    BUTTON_CLOSE_LABEL: t('close'),
    BUTTON_DETAILS_LABEL: t('proposalDetails'),
  };

  return (
    <div className={'proposal-card-footer'}>
      {showingMoreDetails ? (
        <>
          <div>
            <Button
              theme={'default'}
              label={TEXTS.BUTTON_CLOSE_LABEL}
              onClick={() => handleMoreDetails(false)}
            />
          </div>
        </>
      ) : (
        <Button
          theme={'active'}
          label={TEXTS.BUTTON_DETAILS_LABEL}
          onClick={() => handleMoreDetails(true)}
        />
      )}
    </div>
  );
};

const ProposalCardFooter = ({
  isMobile,
  handleMoreDetails,
  showingMoreDetails,
  agreementAccepted,
  handleAcceptProposal,
  isAcceptable,
  handleSeeAgreement,
}) => {
  if (isMobile) {
    return (
      <div>
        <ProposalCardFooterMobile
          handleMoreDetails={handleMoreDetails}
          showingMoreDetails={showingMoreDetails}
          handleSeeAgreement={handleSeeAgreement}
        />
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: '16px' }}>
        <ProposalCardFooterDesktop
          handleMoreDetails={handleMoreDetails}
          handleAcceptProposal={handleAcceptProposal}
          showingMoreDetails={showingMoreDetails}
          isAcceptable={isAcceptable}
          agreementAccepted={agreementAccepted}
        />
      </div>
    );
  }
};

export default ProposalCardFooter;
